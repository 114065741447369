<template>
  <div id="logins">
    <img class="head_img" src="@img/login/login.png" alt="" />
    <div class="table">
      <!-- 确认密码 -->
      <p class="head_name">{{language.l_91}}</p>
      <van-form @submit="onSubmit">
        <!-- 密码 -->
        <p class="mailbox">{{language.l_92}}</p>
        <!-- 请输入您的密码 -->
        <van-field v-model="pwd" type="password" name="pwd" :placeholder="language.l_93" left-icon="lock" clearable :rules="formVerify.pwd" autocomplete="off" class="email"/>
        <!-- 重新输入密码 -->
        <p class="mailbox">{{language.l_94}}</p>
        <!-- 确认密码 -->
        <van-field v-model="rePwd" name="rePwd" type="password" clearable :rules="formVerify.pwd" autocomplete="off" left-icon="lock" :placeholder="language.l_91" class="email">
        </van-field>
        <div class="buttom_lay">
          <!-- 确定修改 -->
          <van-button native-type="submit">{{language.l_95}}</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import {updata_pwd} from "@api";
export default {
  components: {},
  name: "",
  data() {
    return {
      pwd: "",
      rePwd: "",
      language: this.$store.getters.language
    };
  },
  methods: {
    onSubmit(values) {
      //提交表单
      if (this.pwd == this.rePwd) {
        if (typeof this.$route.params.id == "undefined") return this.$toast(language.l_96);
        const { email, code } = this.$route.params.id;
        const { pwd, rePwd } = this;
        updata_pwd({ email, code, pwd, rePwd }).then((res) => {  //密码修改
            this.$toast.success(language.l_97)  //修改成功
            this.$router.push({name: 'Login'})
          }).catch((err) => {
            this.$toast(err)
            this.$router.go(-1)
          });
      } else {
        this.$toast(language.l_98);  //密码不同，请重新输入
      }
    },
  },
  computed: {
    formVerify() {
      const rules = {
        pwd: [{ required: true, message: language.l_99 }],  //密码不能为空
      };
      return rules;
    },
  }
};
</script>
<style lang='less' scoped>
#logins {
  background: #fff;
  height: 100%;
  .head_img {
    width: 100%;
    height: 244px;
  }
  .table {
    padding: 20px 25.5px;
    .head_name {
      font-size: 24px;
      font-weight: bold;
      color: #013358;
    }
    .mailbox {
      padding: 30px 0 10px 0;
      font-size: 14px;
      font-weight: bold;
      color: #013358;
    }
    .email {
      background: #fef3f1;
      border-radius: 4px;
      /deep/ input::-webkit-input-placeholder {
        color: @c_deep;
      }
      /deep/ .van-field__control {
        padding-left: 8px;
      }
      .forget {
        color: @c_deep;
        height: 24px;
        padding-left: 6px;
        border-left: 2px solid @c_deep;
      }
      /deep/ .van-field__left-icon {
        i {
          color: @c_deep;
        }
      }
    }
    .buttom_lay {
      /deep/ button {
        width: 100%;
        border-radius: 8px;
        margin: 40px 0;
        color: #fff;
        background: @c_deep;
      }
    }
    /deep/ .van-field__error-message {
      padding-left: 8px;
    }
  }
}
</style>
